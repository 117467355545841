<template>
  <form @submit.prevent="updateClientsOfSite">
    <modal-card :title="`Which clients can access ${site.fqdn}?`">
      <loading v-if="loading" />
      <switch-selector
        v-else
        :data="mappedUsers"
        :selected="newSelection"
        :disabled="isProcessing"
        placeholder="Filter clients"
      />
      <template slot="footer">
        <button
          v-if="!loading"
          type="submit"
          class="button is-success"
          :class="{ 'is-loading': isProcessing }"
          :disabled="!hasNewSelection || isProcessing"
        >
          Save
        </button>
      </template>
    </modal-card>
  </form>
</template>

<script>
export default {
  name: "ManageClientsOfSiteModal",
  props: {
    siteId: {
      type: String,
      required: true
    },
    resellerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      isProcessing: false,
      oldSelection: {},
      newSelection: {}
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    users() {
      return this.$store.getters["reseller/users"](this.resellerId);
    },
    mappedUsers() {
      return this.$_(this.users)
        .map(user => {
          return {
            id: user._id,
            label: user.email
          };
        })
        .toArray()
        .value();
    },
    oldSelectionIds() {
      return this.$_(this.oldSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    newSelectionIds() {
      return this.$_(this.newSelection)
        .pickBy(selected => !!selected)
        .keys()
        .value();
    },
    hasNewSelection() {
      return !this.$_.isEqual(this.newSelectionIds, this.oldSelectionIds);
    }
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("reseller/observeUsers", {
        resellerId: this.resellerId
      })
      .then(() => {
        this.site.users.forEach(i => {
          const user = this.$_.get(this.users, `${i}`, null);
          if (user) this.$set(this.oldSelection, i, true);
        });
      })
      .finally(() => {
        this.$set(this, "newSelection", this.$_.clone(this.oldSelection));
        this.loading = false;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("reseller/unobserveUsers", {
      resellerId: this.resellerId
    });
  },
  methods: {
    updateClientsOfSite() {
      this.isProcessing = true;
      this.$store
        .dispatch("reseller/updateClientsOfSite", {
          siteId: this.siteId,
          users: this.newSelectionIds,
          resellerId: this.resellerId
        })
        .then(result => {
          this.$emit("close");
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.isProcessing = false;
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
